$roboto:'Roboto', sans-serif;
$green1:#195f4f;
$green2:#114337;
$green3:#53757e;
$black:#000000;
$white:#ffffff;
$grey1:#515151;
$grey2:#9d9d9e;

@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

@mixin gradient{
	background: rgba(17,67,55,1);
	background: -moz-linear-gradient(left, rgba(17,67,55,1) 0%, rgba(25,95,79,1) 30%, rgba(25,95,79,1) 70%, rgba(17,67,55,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(17,67,55,1)), color-stop(30%, rgba(25,95,79,1)), color-stop(70%, rgba(25,95,79,1)), color-stop(100%, rgba(17,67,55,1)));
	background: -webkit-linear-gradient(left, rgba(17,67,55,1) 0%, rgba(25,95,79,1) 30%, rgba(25,95,79,1) 70%, rgba(17,67,55,1) 100%);
	background: -o-linear-gradient(left, rgba(17,67,55,1) 0%, rgba(25,95,79,1) 30%, rgba(25,95,79,1) 70%, rgba(17,67,55,1) 100%);
	background: -ms-linear-gradient(left, rgba(17,67,55,1) 0%, rgba(25,95,79,1) 30%, rgba(25,95,79,1) 70%, rgba(17,67,55,1) 100%);
	background: linear-gradient(to right, rgba(17,67,55,1) 0%, rgba(25,95,79,1) 30%, rgba(25,95,79,1) 70%, rgba(17,67,55,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#114337', endColorstr='#114337', GradientType=1 );
}

body{
	font-family: $roboto;
	ul{
		margin: 0;
		list-style: none;
		padding: 0;
	}
	h1,h2,h3,h4,h5,h6,p,ol,a{
		margin: 0;
		font-weight: 400;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
}

a.all_click{
	@include fill;
}

div.vertical_height{
	@include fill;
	> div{
		display: table;
		width: 100%;
		height: 100%;
		> div{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
}

div.default_font{
	font-size: 14px;
	line-height: 21px;
	h1{
		font-size: 65px;
		line-height: 70px;
	}
	h2{
		font-size: 60px;
		line-height: 60px;
	}
	h3{
		font-size: 30px;
		line-height: 40px;
	}
	h4{
		font-size: 24px;
		line-height: 34px;
	}
	h5{
		font-size: 18px;
		line-height: 28px;
	}
	h6{
		font-size: 16px;
		line-height: 26px;
	}
	strong{
		font-weight: 700;
	}
	ul{
		padding-left: 16px;
		li{
			list-style: disc;
		}
	}
	ol{
		padding-left: 0;
		//counter-reset: item;
		> li{
			ol{
				padding-left: 25px;
			}
			/*display: block;
			&:before{
				content: counters(item, ".") " ";
				counter-increment: item;
			}*/
		}
	}
}

div.text_desc{
	div.default_font{
		h1,h2,h3,h4,h5,h6,p,ol,ul,a{
			padding-bottom: 20px;
			&:last-child{
				padding-bottom: 0;
			}
		}
	}
}

div.page{
	overflow: hidden;
	position: relative;
}

header.header{
	padding: 8px 0;
	div.logo_box{
		position: relative;
		img{
			margin-left: 18px;
			max-width: 100%;
			height: auto;
		}
	}
	div.menu_box{
		padding-top: 70px;
		padding-right: 9px;
		nav.menu{
			> ul{
				text-align: right;
				> li{
					display: inline-block;
					vertical-align: top;
					margin-right: 30px;
					&:last-child{
						margin-right: 0;
					}
					> a{
						font-size: 14px;
						color: $black;
						text-transform: uppercase;
						font-weight: 600;
						transition: 0.3s all ease;
						&:hover{
							color: #297825;
						}
					}
					&.on{
						a{
							color: #297825;
						}
					}
				}
			}
		}
	}
}

section.slider{
	width: 100%;
	@include gradient;
	div.slides{
		width: 100%;
		height: 0;
		padding-bottom: 50.29%;
		position: relative;
		> ul{
			@include fill;
			> li{
				@include fill;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				div.slide_logo{
					width: 35.5%;
					height: 0;
					padding-bottom: 35.5%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					margin: 0 auto;
				}
			}
		}
	}
}

section.squares{
	padding-top: 5%;
	padding-bottom: 5%;
	div.square_list{
		> ul{
			> li{
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 25%;
				height: 243px;
				&:nth-child(odd){
					background: $green2;
				}
				&:nth-child(even){
					background: $green1;
				}
				> div{
					padding: 27px 24px 23px 24px;;
					div.icon{
						margin-bottom: 24px;
					}
					div.desc{
						div.default_font{
							color: $white;
						}
					}
				}
			}
		}
	}
}

header.section_header{
	//padding-bottom: 7%;
	padding-bottom: 5%;
	div.default_font{
		text-align: center;
		h1,h2,h3,h4,h5,h6{
			text-transform: uppercase;
			color: $green3;
			font-weight: 700;
		}
	}
}

section.news{
	padding-bottom: 5%;
	article{
		position: relative;
		div.image{
			height: 160px;
			width: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}
		div.desc{
			> div{
				padding-left: 14px;
				padding-right: 14px;
			}
			border: 1px solid $grey2;
			div.date{
				padding-top: 11px;
				padding-bottom: 11px;
				font-size: 11px;
				border-bottom: 1px solid $grey2;
				color: $grey2;
			}
			div.default_font{
				padding-top: 18px;
				padding-bottom: 26px;
				font-size: 10px;
				line-height: 12px;
				color: $grey2;
				h1,h2,h3,h4,h5,h6{
					color: $grey1;
					padding-bottom: 10px;
				}
			}
		}
	}
}

footer.footer{
	div.footer_content{
		@include gradient;
		section.squares{padding:0;}
		> div.footer_top{
			div.square_list{
				> ul{
					> li{
						position: relative;
						display: inline-block;
						vertical-align: top;
						width: 25%;
						height: 187px;
						&:nth-child(odd){
							background: $green1;
						}
						&:nth-child(even){
							background: $green2;
						}
						> div{
							padding: 27px 15px 23px 15px
						}
						div.desc{
							div.default_font{
								font-size: 11px;
								line-height: 13px;
								p{
									text-transform: uppercase;
								}
								h4{
									padding-top: 18px;
								}
								h5{
									padding-top: 22px;
								}
								h1,h2,h3,h4,h5,h6{
									a{
										color: $white;
									}
								}
							}
						}
					}
				}
			}
		}
		> div.footer_middle{
			div.padding_top{
				padding-top: 35px;
			}
			div.menu_box{
				padding-top: 26px;
				> div.default_font{
					padding-bottom: 20px;
					color: $white;
				}
				> nav.menu2{
					> ul{
						> li{
							margin-bottom: 20px;
							&:last-child{
								margin-bottom: 0;
							}
							> a{
								color: $white;
								font-size: 12px;
							}
						}
					}
				}
			}
			div.widget{
				margin-top: 11px;
			}
			section.map{
				margin-top: 18px;
				position: relative;
				padding-bottom: 24%;
				width: 100%;
				> iframe{
					@include fill;
				}
			}
		}
		> div.footer_bottom{
			padding: 33px 0 31px 0;
			color: $white;
			p{
				font-size: 12px;
			}
		}
	}
}

section.image_and_desc{
	padding-bottom: 5%;
	div.text_desc{
		div.default_font{
			color: #114337;
		}
	}
	div.image{
		img{
			max-width: 100%;
			height: auto;
		}
	}
	&.desc_left{
		div.text_desc{
			padding-right: 37px;
		}
	}
	&.desc_right{
		div.text_desc{
			padding-left: 37px;
		}
	}
}

section.prices{
	padding: 3% 0 5% 0;
	margin-bottom: 5%;
	background-color: #13473a;
	header.section_header{
		div.default_font{
			h1,h2,h3,h4,h5,h6{
				color: $white;
			}
		}
	}
	div.text_desc{
		div.default_font{
			color: $white;
			ol{
				li{
					font-size: 24px;
					line-height: 30px;
				}
			}
		}
	}
}

section.members{
	div.members_list{
		padding: 5% 0;
		background-color: #13473a;
		ul{
			> li{
				display: inline-block;
				vertical-align: top;
				width: 33.33%;
				height: 85px;
				margin-bottom: 10%;
				> div.default_font{
					padding: 0 30px;
					color: $white;
					h4{
						line-height: 25px;
						text-transform: uppercase;
					}
					h6{
						line-height: 20px;
						text-transform: uppercase;
					}
				}
			}
		}
		div.text_desc{
			color: $white;
		}
	}
}

section.administration{
	padding: 5% 0;
	div.text_desc{
		padding-top: 150px;
		div.default_font{
			padding-right: 66px;
			color: #114337;
		}
	}
	img{
		max-width: 100%;
		height: auto;
	}
}

section.download{
	table{
		width: 100%;
		border-collapse: separate;
		border-spacing: 0 60px;
		p,a{
			font-size: 14px;
			display: inline-block;
			background-color: $white;
			color: #114337;
			padding: 10px 0;
		}
		p{
			padding-right: 40px;
		}
		a{
			padding-left: 40px;
			padding-right: 40px;
			background-repeat: no-repeat;
			background-position: right center;
			background-image: url('./../img/download_icon.png');
		}
		tr{
			background-repeat: repeat-x;
			background-position: left bottom 17px;
			background-image: url('./../img/table_dots.png');
			> td{
				&:last-child{
					text-align: right;
				}
			}
		}
	}
}

section.books{
	padding: 5% 0;
	div.text_desc{
		div.default_font{
			color: #114337;
		}
	}
}

section.plan{
	div.plan_button{
		margin-bottom: 8%;
		background-color: #13473a;
		cursor: pointer;
		> div.default_font{
			color: $white;
			padding: 13px;
			> h6{
				font-weight: 700;
			}
		}
	}
	table{
		margin-bottom: 4%;
		border-collapse: separate;
    	border-spacing: 13px 5px;
		width: 100%;
		
		thead{
			> tr{
				> th{
					background-color: #297825;
					border: 4px solid #297825;
				}
			}
		}
		tbody{
			> tr{
				> td{
					border: 4px solid #13473a;
					p{
						color: #13473a;
					}
					&:first-child{
						background-color: #13473a;
						p{
							color: $white;
						}
					}
				}
			}
		}
		tr{
			> th,td{
				text-align: center;
				width: 15%;
				> p{
					font-size: 16px;
					padding: 11px 0;
					color: $white;
				}
			}
		}
	}
	.plan_hidden{
		display: none;
	}
}

section.year_calendar{
	padding: 3% 0;
	background-color: #13473a;
	div.default_font{
		color: $white;
		h1,h2,h3,h4,h5,h6{
			color: $white;
		}
	}
}

section.news_details{
	padding: 5% 0;
	header.news_header{
		padding-bottom: 5%;
		div.date{
			padding-bottom: 5%;
			color: #9d9d9e;
			font-size: 16px;
		}
		div.default_font{
			color: #515151;
		}
	}
	div.text_desc{
		div.default_font{
			color: #9d9d9e;
		}
	}
}

section.news_details_naw{
	div.button1{
		display: inline-block;
		vertical-align: top;
		position: relative;
		h5{
			background-repeat: no-repeat;
			margin-bottom: 10px;
		}
		p{
			color: #9d9d9e;
		}
		&.button_left{
			div{
				h5{
					background-position: left bottom;
					background-image: url('./../img/arr_left.png');
					line-height: 10px;
					color: #195f4f;
				}
				h5,p{
					padding-left: 20px;
				}
			}
		}
		&.button_right{
			float: right;
			div{
				h5{
					background-position: right bottom;
					background-image: url('./../img/arr_right.png');
					line-height: 10px;
					color: #195f4f;
				}
				h5,p{
					padding-right: 20px;
				}
			}
		}
	}
}

@media only screen and (min-width: 992px) and (max-width: 10000px) {
	div.menu_box nav.menu{display: block!important;}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	header.header div.menu_box nav.menu > ul > li > a{font-size: 12px;}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	section.squares div.square_list > ul > li{width: 50%!important;}
	section.news article div.image{height: 260px;}
	
	section.members div.members_list ul > li{width: 50%; height: auto; margin-bottom: 30px;}
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	section.news article div.image{height: 0; padding-bottom: 100%;}
	section.squares div.square_list > ul > li{width: 100%!important;}
	div.square_list > ul > li{height: auto!important; text-align: center;}
	
	div.footer_middle div.menu_box{text-align: center;}
	footer.footer div.footer_content > div.footer_bottom{text-align: center;}
	footer.footer div.footer_content > div.footer_middle div.widget{text-align: center;}
	
	section.members div.members_list ul > li{width: 100%; height: auto; margin-bottom: 30px;}
	
	div.plan_hidden{overflow: scroll;}
	section.plan table tr > th, section.plan table tr td{width: auto;}
	section.plan table tr > th > p, section.plan table tr td > p{padding: 11px 30px;}
	
	section.download table tr{background-image: none;}
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
	div.default_font h2{font-size: 30px; line-height: 30px;}
	
	main.content > section{padding: 30px 0!important;}
	header.section_header{padding: 15px 0;}
	
	section.news article{margin-bottom: 30px;}
	footer.footer div.square_list > ul > li{background-color: transparent!important;}
	footer.footer div.footer_content > div.footer_middle section.map{padding-bottom: 200px;}
	
	header.header div.logo_box img{width: 70px; height: auto; margin-left: 0;}
	div.rwd_button{width: 30px; position: absolute; top: 15px; right: 0;}
	div.rwd_button > div{width: 100%; height: 3px; margin-bottom: 3px; background-color: $green2;}
	div.rwd_button > div:last-child{margin-bottom: 0;}
	div.menu_box nav.menu{display: none;}
	header.header div.menu_box nav.menu > ul {padding-top: 15px; text-align: center;}
	header.header div.menu_box nav.menu > ul > li{width: 100%; margin-right: 0; margin-bottom: 10px;}
	
	header.header div.menu_box{padding-right: 0; padding-top: 0;}
	
	section.image_and_desc.desc_right div.text_desc{padding-left: 0;}
	section.image_and_desc.desc_left div.text_desc{padding-right: 0;}
	section.image_and_desc.desc_right div.row{display: flex; flex-flow: column;}
	section.image_and_desc.desc_right div.row > div.col-md-7{order: 1; align-self: stretch;}
	section.image_and_desc.desc_right div.row > div.col-md-5{order: 2; align-self: stretch;}
	section.image_and_desc div.text_desc{padding-bottom: 30px;}
	
	section.prices div.text_desc div.default_font ol li{font-size: 14px; line-height: 20px;}
	
	section.administration div.text_desc{padding-top: 0;}
	section.administration div.text_desc div.default_font{padding-right: 0; padding-bottom: 30px; text-align: center;}
	
	section.year_calendar{margin-bottom: 0!important;}
}